<template>
<span class="card-modal" :class="{[`modal-active ${activeModalView}`]: modalActive}">
  <v-card-text class="pa-2" :class="{'completed': thought.isCompleted}">
    <thought-card-edit
            :class="{['content-active']: modalActive}"
            v-if="activeModalView === 'edit'"
            :thoughtId="thought.id"
            :modalActive="modalActive"
            :closeModal="closeModal"
            @action="onAction">
    </thought-card-edit>

    <thought-card-delete
            :class="{['content-active']: modalActive}"
            v-if="activeModalView === 'delete'"
            :thoughtId="thought.id"
            :closeModal="closeModal"
            @action="onAction">
    </thought-card-delete>
  </v-card-text>
</span>
</template>

<script>
import thoughtCardEdit from '@/views/tools/thoughts/components/card-modal/thought-card-edit.vue';
import thoughtCardDelete from '@/views/tools/thoughts/components/card-modal/thought-card-delete.vue';

export default {
  name: 'thoughtCardModal',
  components: {
    thoughtCardEdit,
    thoughtCardDelete,
  },
  props: [ 'thought', 'activeModalView', 'modalActive', 'closeModal' ],
  methods: {
    onAction(event) {
      this.$log.debug('on action', this.activeModalView);
      this.$emit(this.activeModalView);
    },
  },
};
</script>
