<template>
<v-card class="thought-card">

  <thought-card-header
          :thought="thought"
          :activeModalView="activeModalView"
          :toggleModal="toggleModal">
  </thought-card-header>

  <v-divider light></v-divider>

  <thought-card-modal
          :thought="thought"
          :modalActive="modalActive"
          :activeModalView="activeModalView"
          :closeModal="closeModal"
          class="scale-in-center"
          @edit="onEdit"
          @delete="onDelete">
  </thought-card-modal>

  <thought-card-content
          :thought="thought"
          :class="{hidden: modalActive}"
          class="scale-in-center">
  </thought-card-content>

</v-card>
</template>

<script>
import thoughtCardHeader from '@/views/tools/thoughts/components/thought-card-header.vue';
import thoughtCardModal from '@/views/tools/thoughts/components/card-modal/thought-card-modal.vue';
import thoughtCardContent from '@/views/tools/thoughts/components/thought-card-content.vue';

export default {
  name: 'thoughtCard',
  components: {
    thoughtCardHeader,
    thoughtCardModal,
    thoughtCardContent,
  },
  props: {
    thought: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeModalView: false,
      modalActive: false,
    };
  },
  methods: {
    toggleModal(viewName) {
      if (this.$route.name !== 'thoughtsAction') {
        if (this.activeModalView === viewName) {
          this.activeModalView = false;
          this.modalActive = false;
        } else {
          this.activeModalView = viewName;
          this.modalActive = true;
        }
      }
    },
    closeModal() {
      this.modalActive = false;
      this.activeModalView = false;
    },
    onDelete() {
      this.$emit('delete', this.thought);
    },
    onEdit() {
      this.$emit('edit', this.thought);
    },
  },
};
</script>

<style scoped>
.thought-card {
  border-radius: 15px;
}
</style>
