<template>
<v-card-title>
  <span class="starling-body">{{formatDate(thought.timestamp)}}</span>
  <span class="starling-body ml-3">{{formatHour(thought.timestamp)}}</span>
  <v-card-actions class="header-icons">
    <v-spacer></v-spacer>
    <v-icon class="edit-action" :class="{'active': activeModalView === 'edit'}" @click.native="toggleModal('edit')" v-if="!isHome">
      edit
    </v-icon>
    <v-icon class="delete-action" :class="{'active': activeModalView === 'delete'}" @click.native="toggleModal('delete')" v-if="!isHome">
      delete
    </v-icon>
  </v-card-actions>
</v-card-title>

</template>

<script>
import * as moment from 'moment';

export default {
  name: 'thoughtCardHeader',
  props: {
    thought: {
      type: Object,
      required: true,
    },
    toggleModal: {
      type: Function,
      required: true,
    },
    isHome: {
      type: Boolean,
      required: false,
    },
    activeModalView: {
      type: [ String, Boolean ],
      required: true,
    },
  },
  computed: {
    thoughtWithType() {
      return { ...this.thought, type: 'thought' };
    },
  },
  methods: {
    editThought() {
      this.$router.push({ name: 'thoughts-edit', params: { id: this.thought.id } });
    },
    formatDate(timestamp) {
      return moment(timestamp).format('LL');
    },
    formatHour(timestamp) {
      return moment(timestamp).format('LT');
    },
  },
};
</script>
<style scoped>
h5 {
  text-transform: capitalize;
}
</style>
