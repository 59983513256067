var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card-text",
        { staticClass: "pa-2" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "gap-xs-2": "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("span", { staticClass: "starling-body" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("app.tools.thought_balancer.thought_card.balanced")
                    )
                  )
                ]),
                _c(
                  "blockquote",
                  { staticClass: "starling-text thought-item" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.thought.balanced) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _vm.seeMore
                ? [
                    _c("v-flex", { attrs: { xs12: "" } }, [
                      _c("span", { staticClass: "starling-body" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "app.tools.thought_balancer.thought_card.initial"
                            )
                          )
                        )
                      ]),
                      _c(
                        "blockquote",
                        { staticClass: "starling-text thought-item" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.thought.initial) +
                              "\n          "
                          )
                        ]
                      )
                    ]),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("span", { staticClass: "starling-body" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "app.tools.thought_balancer.thought_card.facts_for"
                              )
                            )
                          )
                        ]),
                        _vm._l(_vm.thought.factsFor, function(factFor) {
                          return _c(
                            "blockquote",
                            {
                              key: "factFor-" + factFor.id,
                              staticClass: "starling-text thought-item"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(factFor.title) +
                                  "\n          "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("span", { staticClass: "starling-body" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "app.tools.thought_balancer.thought_card.facts_against"
                              )
                            )
                          )
                        ]),
                        _vm._l(_vm.thought.factsAgainst, function(factAgainst) {
                          return _c(
                            "blockquote",
                            {
                              key: "factAgainst-" + factAgainst.id,
                              staticClass: "starling-text thought-item"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(factAgainst.title) +
                                  "\n          "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        {
          staticClass: "pa-2",
          attrs: { "justify-center": "", "align-center": "" }
        },
        [
          _c(
            "v-btn",
            {
              attrs: { flat: "", small: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.seeMore = !_vm.seeMore
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    this.seeMore
                      ? _vm.$t(
                          "app.tools.thought_balancer.thought_card.see_less"
                        )
                      : _vm.$t(
                          "app.tools.thought_balancer.thought_card.see_more"
                        )
                  ) +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }