<template>
<div>
  <v-card-text class="pa-2">
    <v-layout row wrap gap-xs-2>
      <v-flex xs12>
        <span class="starling-body">{{$t('app.tools.thought_balancer.thought_card.balanced')}}</span>
        <blockquote class="starling-text thought-item">
          {{thought.balanced}}
        </blockquote>
      </v-flex>

      <template v-if="seeMore">
        <v-flex xs12>
          <span class="starling-body">{{$t('app.tools.thought_balancer.thought_card.initial')}}</span>
          <blockquote class="starling-text thought-item">
            {{thought.initial}}
          </blockquote>
        </v-flex>

        <v-flex xs12>
          <span class="starling-body">{{$t('app.tools.thought_balancer.thought_card.facts_for')}}</span>
          <blockquote class="starling-text thought-item" v-for="factFor in thought.factsFor" :key="`factFor-${factFor.id}`">
            {{factFor.title}}
          </blockquote>
        </v-flex>

        <v-flex xs12>
          <span class="starling-body">{{$t('app.tools.thought_balancer.thought_card.facts_against')}}</span>
          <blockquote class="starling-text thought-item" v-for="factAgainst in thought.factsAgainst" :key="`factAgainst-${factAgainst.id}`">
            {{factAgainst.title}}
          </blockquote>
        </v-flex>
      </template>
    </v-layout>
  </v-card-text>
  <v-card-actions justify-center align-center class="pa-2">
    <v-btn flat small color="primary" @click="seeMore=!seeMore">
      {{this.seeMore?$t('app.tools.thought_balancer.thought_card.see_less'):$t('app.tools.thought_balancer.thought_card.see_more')}}
    </v-btn>
  </v-card-actions>
</div>
</template>

<script>

export default {
  name: 'thoughtCardContainer',
  props: [ 'thought', 'isHome' ],
  data() {
    return {
      seeMore: false,
    };
  },
};
</script>
