<template>
<div class="modal-content">
  <p class="starling-explanatory mb-2">{{ $t('app.tools.thought_balancer.thought_card.edit') }}</p>
  <action-buttons
          :primary="{label : $t('common.actions.edit'), disabled : false, loading : false}"
          @primary-clicked="sendEditEvent"
          :secondary="{label : $t('common.actions.cancel'),disabled : false, loading : false}"
          @secondary-clicked="closeModal">
  </action-buttons>
</div>
</template>

<script>
import actionButtons from '@/views/components/navigation/action-buttons.vue';

export default {
  name: 'thoughtCardEdit',
  components: {
    actionButtons,
  },
  props: [ 'thoughtId', 'closeModal' ],
  methods: {
    sendEditEvent() {
      this.$emit('action');
    },
  },
};
</script>
