var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-title",
    [
      _c("span", { staticClass: "starling-body" }, [
        _vm._v(_vm._s(_vm.formatDate(_vm.thought.timestamp)))
      ]),
      _c("span", { staticClass: "starling-body ml-3" }, [
        _vm._v(_vm._s(_vm.formatHour(_vm.thought.timestamp)))
      ]),
      _c(
        "v-card-actions",
        { staticClass: "header-icons" },
        [
          _c("v-spacer"),
          !_vm.isHome
            ? _c(
                "v-icon",
                {
                  staticClass: "edit-action",
                  class: { active: _vm.activeModalView === "edit" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.toggleModal("edit")
                    }
                  }
                },
                [_vm._v("\n      edit\n    ")]
              )
            : _vm._e(),
          !_vm.isHome
            ? _c(
                "v-icon",
                {
                  staticClass: "delete-action",
                  class: { active: _vm.activeModalView === "delete" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.toggleModal("delete")
                    }
                  }
                },
                [_vm._v("\n      delete\n    ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }